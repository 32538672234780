<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h4 class="fw-bold text-primary">Create Order - Dispatch Note</h4>
          <div>
            <div class="form mt-3">
              <div class="row">
                <strong>1. Dispatch Particulars</strong>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Assignee Name</label>
                    <select v-model="assignedToId" class="full" id="users"  v-if="users?.length > 0">
                        <option v-for="user in users" v-bind:Key="user.id" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No User Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchUsers">Fetch Users</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Warehouse</label>
                    <select v-model="warehouseId" class="full" id="warehouses"  v-if="warehouses?.length > 0">
                        <option v-for="warehouse in warehouses" v-bind:Key="warehouse.id" :value="warehouse.id">
                            {{ warehouse.name }} <span v-if="warehouse.isVirtual == true"> - Virtual</span>
                        </option>
                    </select>
                    <div v-else>
                        <h5 for="" style="color: red !important;">No Warehouse Found.</h5> 
                        <button class="btn btn-primary btn-sm" @click="this.fetchWarehouses">Fetch Warehouses</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Urgent Dispatch?</label>
                        <div class="components-button">
                          <span class="switcher switcher-1">
                            <input type="checkbox" id="switcher-1" v-model="isUrgent">
                            <label for="switcher-1"></label>
                          </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Project</label>
                    <!--<input type="text" id="address" class="form-control" v-model="project">-->
                    <select v-model="project" class="full" id="projects"  v-if="projects?.length > 0">
                      <option v-for="proj in projects" v-bind:Key="proj.id" :value="proj.name">
                          {{ proj.name }}
                      </option>
                    </select>
                    <div v-else>
                      <h5 for="" style="color: red !important;">No Projects Found.</h5> 
                      <button class="btn btn-primary btn-sm" @click="this.fetchProjects">Fetch Projects</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Purpose of Dispatch</label>
                    <select v-model="purposeId" class="full" id="purposes"  v-if="purposes?.length > 0">
                          <option v-for="purpose in purposes" v-bind:Key="purpose.id" :value="purpose.id"> {{ purpose.purpose }} </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No Purpose Found.</h5> 
                          <button class="btn btn-primary btn-sm" @click="this.fetchPurposesSpecify('Dispatch')">Fetch Purposes</button>
                      </div>
                  </div>
                </div>
                
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="address">Reference No.</label>
                    <input type="text" id="address" class="form-control" v-model="reference">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="address">Upload Document</label>
                    <input type="file" id="document" class="form-control" ref="fileDocument" @change="handleFileChange">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="address">Dispatch Instructions/Comments</label>
                    <input type="text" id="address" class="form-control" v-model="instructions">
                  </div>
                </div>

                <div class="col-md-2">
                    <button class="btn-style small " style="margin-top: 31px" @click="postDispatch">Add</button>
                </div>
  
              </div>
  
            </div>
          </div>
          <div :style="{ 'display': displayItems }" style="margin-top:30px; margin-bottom: 30px !important">
            <strong>2. Add Items</strong>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="supplierContact">Product Description</label>
                  <Select2 class="select2" v-if="products.length > 0" style="width: 100% !important;" v-model="productId" :options="productOptions" :settings="{ width: 'resolve', selectionCssClass: ':all:' }" />
                  <div v-else>
                      <h5 for="" style="color: red !important;">No Product Found in that warehouse.</h5> 
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Unit</label>
                  <input class="form-control" type="text"  readonly v-model="productUnit" />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Quantity Available</label>
                  <input class="form-control" type="number" min="0" v-model="quantityAvailable" disabled />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Quantity Assigned</label>
                  <input class="form-control" type="number" min="0" v-model="quantity"/>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <label for="supplierContact">Condition</label>
                  <select v-model="condition" id="" class="full">
                    <option value="New">New</option>
                    <option value="Used">Used</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="supplierContact">Comments</label>
                  <input class="form-control" type="text" v-model="comment"/>
                </div>
              </div>
              <div class="col-md-1">
                <div class="form-group">
                  <button class="btn-style small" style="margin-top: 25px" @click="postItem">Add</button>
                </div>
              </div>
            </div>
              
            <strong class="mt-5">3. Item List</strong>
            <table class="table table-hover">
                <thead>
                    <th>No. </th>  
                    <th>Product Description</th>
                    <th>Product Code</th>
                    <th>Unit</th>
                    <th>Qty Assigned</th>
                    <th>Condition</th>
                    <th>Comments</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="items?.length > 0">
                    <tr v-for="(item, index) in items" v-bind:key="item.id">
                        <td>{{ (index+1) }}</td>
                        <td>{{ item.Product.name }}</td>
                        <td>{{ item.Product.code }}</td>
                        <td>{{ item.Product?.unit?.unit }}</td>
                        <td>{{ formatQty(item.quantity) }}</td>
                        <td>{{ item.condition }}</td>
                        <td>{{ item.comment }}</td>
                        <td>
                            <button class="btn btn-default btn-sm" style="border:1px solid black;" @click="deleteItem(item.id)">Remove</button>
                        </td>
                    </tr>
                </tbody>

                <!--
                  <div v-else>
                  <label for="" class="alert alert-danger" style="margin: 10px auto;  display: block;">No items found</label>
                </div>
                -->
              
            </table>

            <div>
              <button class="btn-style small" style="border: 1px solid black; float: right;" @click="generateDispatch">Generate</button>
              <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="deleteDispatch">Close</button>
            </div>
  
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>

  import { mapGetters, mapActions } from 'vuex'
  import Swal from 'sweetalert2'
  import Header from '../../../components/Header';
  import axios from 'axios'
  import Select2 from 'vue3-select2-component';
  
  export default {
    name: 'AddDispatch',
    components: {
      Header,
      Select2,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        displayItems: "none",
        isUrgent: false,
        items: [],
        products: [],
        productOptions:[],
        productId: "",
        productIdPost:"",
        assignedToId:"",
        company:"",
        phone:"",
        email:"",
        pickupDate:"",
        project:"",
        instructions:"",
        warehouseId:"",
        purposeId:"",
        document:"",
        reference:"",
        quantity:"",
        quantityAvailable:"",
        condition: "",
        comment: "",
        batchNo: "",
        dispatchId:"",
    }),
    computed:{
        ... mapGetters (["getNogs", "getWarehouses", "getPurposeSpecify", "getUsers", "getProjects"]),
        nogs () { return this.getNogs },
        warehouses () { return this.getWarehouses },
        purposes () { return this.getPurposeSpecify },
        users () { return this.getUsers },
        projects () { return this.getProjects}
    },
    mounted(){
        this.fetchNogs(),
        this.fetchWarehouses(),
        this.fetchPurposesSpecify("Dispatch"),
        this.fetchUsers()
        this.fetchProjects()
    },
    methods:{
        ... mapActions(['fetchNogs', 'fetchWarehouses','fetchPurposesSpecify','fetchUsers', 'fetchProjects']),
        async generateDispatch () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/dispatches/generate/'+this.dispatchId, requestOptions)
            const data = await res.json()
            if(data.status == true){
                Swal.fire({
                  position: 'top-center',
                  icon: 'success',
                  title: 'Dispatch Note Generated',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.$router.push('/orders')          
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Dispatch Note not generated',
                    text: data.message
                })
            }
        },

        async deleteDispatch () {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
            };
            const res = await fetch(process.env.VUE_APP_URL+'/dispatches/delete/'+this.dispatchId, requestOptions)
            console.log("Response", res)
            const data = await res.json()
            if(data.status == true){
                this.$router.push('/orders')   
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Dispatch Note Deletion Failed',
                    text: data.message
                })
            }
        },


        async postItem () {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  quantity:this.quantity,
                  condition:this.condition,
                  comment:this.comment,
                  batchNo:this.batchNo,
                  dispatchNoteId:this.dispatchId,
                  productId:this.productIdPost
                })
            };
            if(this.quantity <= this.quantityAvailable){
              const res = await fetch(process.env.VUE_APP_URL+'/dispatches/items', requestOptions)
              const data = await res.json()
              if(data.status == true){
                this.quantity =""
                this.condition = ""
                this.comment = ""
                this.items = data.products;     
              }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Dispatch Note not created',
                    text: data.message
                })
              }
            }else{
              Swal.fire({
                icon: 'error',
                text: 'Cannot assign more quantity than available'
              })
            }
        },
        async postDispatch () {
          let formData = new FormData()
          formData.append("assignedToId", this.assignedToId)
          formData.append("assigneeCompany", this.company)
          formData.append("assigneePhone", this.phone)
          formData.append("assigneeEmail", this.email)
          formData.append("isUrgent", this.isUrgent)
          formData.append("pickupDate", this.pickupDate)
          formData.append("project", this.project)
          formData.append("instructions", this.instructions)
          formData.append("warehouseId", this.warehouseId)
          formData.append("purposeId", this.purposeId)
          formData.append("reference", this.reference)
          formData.append("file", this.document)

          axios.post(process.env.VUE_APP_URL+'/dispatches', formData, 
            { headers:{ 
              "Content-Type": "multipart/form-data", 
              'Authorization': 'Bearer '+this.token 
            }}).then(function(data) {
            if(data.data.status == true){
              this.dispatchId = data.data.dispatch.id
              this.products = data.data.products
              this.displayItems = "block"

              this.productOptions = [];
              this.products.forEach(product => {
                this.productOptions.push({ id: product.Product?.id, text: product.Product?.name+" - "+product?.Product?.unit?.unit})
              });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Dispatch Note not created',
                    text: data.data.message
                })
            }
          }.bind(this))
        },
        handleFileChange() {
          this.document = this.$refs.fileDocument.files[0];
        },
        async deleteItem (itemId) {
          const requestOptions = {
              method: "DELETE",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token }
          };
          const res = await fetch(process.env.VUE_APP_URL+'/dispatches/items/'+itemId+"/"+this.dispatchId, requestOptions)
          const data = await res.json()
          if(data.status == true){
            this.items = data.items;     
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Item not deleted',
                text: data.message
            })
          }
        },
        changeProduct(){
          //console.log(this.productId)
        }

      },
      watch:{
        productId(newValue){
          // console.log({newValue})
          // console.log(this.products)
          // var productObj = this.products.find(i => i.Product.id === newValue);
          // console.log({productObj})
          // this.productUnit = productObj.Product?.unit?.unit
          // this.productIdPost = productObj.Product?.id
          // this.quantityAvailable = productObj.quantity
          // console.log(this.productIdPost)
          
          const parsedValue = parseInt(newValue)
          let productObj = this.products.find(i => i.Product.id === parsedValue);
          this.productUnit = productObj.Product?.unit?.unit
          this.productIdPost = productObj.Product?.id
          this.quantityAvailable = productObj.quantity
        },
      }
  }
  </script>

<style lang="scss" scoped>
$text-color: #002060;
input[readonly]{
  background-color: #cfcece !important;
}

select {
  /* inline SVG */
  background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 3px;
  height: 45px;
  border: 1px solid #b4c7e7 !important;
  padding: 5px 30px 5px 10px;
  // disable default appearance
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand { display: none };
  &.full{
    width: 100%;
  }
  &:focus, &:hover{
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"), #fff;
    border: 1px solid $text-color;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
  }
}


$primary: #d2d2d2;
$green: #4448B3;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                    height: 30px !important;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                    height: 30px !important;
                  }
                }
              }
            }
          }
        }
      }
  </style>