<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="transaction-products">
        <div class="container">
  
          <h4 style="margin-bottom: 0px;" class="fw-bold text-primary">Reports</h4>  
          <div class="filter-search" style="margin-top: 0px; padding-left: 0px;">
            <div class="filter-search-header">
              <strong>1. Dispatch Report</strong>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="ddateFrom">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="ddateTo">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <button class="btn-style small" @click="exportDispatch">Export Report</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>

            <div class="filter-search-header">
              <strong>2. Pickup Report</strong>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="pdateFrom">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="pdateTo">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <button class="btn-style small" @click="exportPickup">Export Report</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            
            <div class="filter-search-header">
              <strong>3. Receiving Report</strong>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="bdateFrom">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="bdateTo">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <button class="btn-style small" @click="exportBatch">Export Report</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>

            <div class="filter-search-header">
              <strong>4. Incomplete Orders</strong>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date From</label>
                      <input type="date" class="form-control" v-model="idateFrom">
                    </div>
                  </div>

                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date To</label>
                      <input type="date" class="form-control" v-model="idateTo">
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <button class="btn-style small" @click="exportIdn">Export Report</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>


          </div>
            
        </div>
      </section>
  
      <div id="pdfLoader" style="position: absolute; width: 100%; height: 2000px; top:0; z-index:1;background-color: #f2f2f2; display: none;">
        <h3 style="margin-top: 100px; text-align: center;padding: 40px;">Wait as the PDF is being generated</h3>
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </div>
  </template>
  
  <script>
  import Header from '../../components/Header';
  import exportFromJSON from 'export-from-json';
  import Swal from 'sweetalert2'
  
  export default {
    name: 'ReportsHome',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        activities: [],
        productName:"",
        whName:"",
        whVirtual:"",
        whDesc:"",
        ddateFrom:"",
        ddateTo:"",
        pdateFrom:"",
        pdateTo:"",
        bdateFrom:"",
        bdateTo:"",
        idateFrom: "", 
        idateTo: ""
    }),
    mounted(){
        // this.getActivities(this.$route.params.id)
    },
    methods:{
       async  exportDispatch(){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    dateFrom:this.ddateFrom,
                    dateTo:this.ddateTo
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/dispatches/report', requestOptions)
            const data = await res.json()
            if(data.status == true){
                //console.log(data.dispatches)
                const rawDispatches = data.dispatches;
                //console.log({rawDispatches})
                const dispatchDatas = rawDispatches.map((dispatch, index) => ({
                    'No.': index + 1,
                    'Date': this.formatDate(dispatch.createdAt, true),
                    'Product Category': dispatch.category,
                    'DN No.': dispatch.dispatchNo,
                    'Ref No.': dispatch.reference,
                    'Product Description': dispatch.ProductName,
                    'Unit': dispatch.unit,
                    'Quantity Requested': dispatch.quantity,
                    'Quantity Dispatched': dispatch.quantityDispatched,
                    'Balance': dispatch.InventoryQuantity,
                    'W/House': dispatch.name,
                    'Requested by': dispatch.RequestedBy,
                    'Dispatched by': dispatch.DispatchedBy,
                    'Dispatched to': dispatch.AssignedToBy,
                    'Assigned to': dispatch.AssignedToBy,
                    'Project/Site Name': dispatch.project,
                    'Vehicle': dispatch.vehicle,
                    'Status': dispatch.status,
                }));
                const fileName = new Date().getTime()+'_dispatch_report';
                const exportType = 'csv';
                
                const fields = ['No.', 'Date', 'Product Category', 'DN No.','Ref No.', 'Product Description', 'Unit', 'Quantity Requested', 
                'Quantity Dispatched', 'Balance', 'W/House', 'Dispatched by', 'Dispatched to','Assigned to', 'Project/Site Name', 'Vehicle', 'Status'];
                //console.log({dispatchDatas})
                 console.log(fields)
                //exportFromJSON({ dispatchDatas, fileName, exportType, fields });
                exportFromJSON({ data:dispatchDatas, fileName, exportType, fields });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'No Dispatch note found',
                    text: data.message
                })
            }
      }, 


       async exportPickup(){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    dateFrom:this.pdateFrom,
                    dateTo:this.pdateTo
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/pickups/report', requestOptions)
            const data = await res.json()
            if(data.status == true){
                const rawDispatches = data.pickups;
                const dispatchDatas = rawDispatches.map((dispatch, index) => ({
                    'No.': index + 1,
                    'Date': this.formatDate(dispatch.createdAt, true),
                    'Product Category': dispatch.category,
                    'PN No.': dispatch.pickupNo,
                    'Ref No.': dispatch.reference,
                    'Product Description': dispatch.ProductName,
                    'Unit': dispatch.unit,
                    'Quantity Requested': dispatch.quantity,
                    'Quantity Assigned': dispatch.quantityAssigned,
                    'Balance': dispatch.InventoryQuantity,
                    'W/House': dispatch.name,
                    'Requested by': dispatch.RequestedBy,
                    'Dispatched by': dispatch.DispatchedBy,
                    'Dispatched To': dispatch.assigneeName+" - "+dispatch.assigneeCompany,
                    'Project/Site Name': dispatch.project,
                    'Vehicle': dispatch.vehicle,
                    'Status': dispatch.status,
                }));
                const fileName = new Date().getTime()+'_pickup_report';
                const exportType = 'csv';
                
                const fields = ['No.', 'Date', 'Product Category', 'DN No.','Ref No.', 'Product Description', 'Unit', 'Quantity Requested', 
                'Quantity Dispatched', 'Balance', 'W/House', 'Dispatched by', 'Dispatched to','Assigned to', 'Project/Site Name', 'Vehicle', 'Status'];
                //console.log({dispatchDatas})
                console.log(fields)
                //exportFromJSON({ dispatchDatas, fileName, exportType, fields });
                exportFromJSON({ data:dispatchDatas, fileName, exportType, fields});
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'No Pickup note found',
                    text: data.message
                })
            }
      },

      getDuration(startDate, endDate){
        const start = new Date(startDate);
        const end = new Date(endDate);
        const differenceInTime = end - start;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays;
      },
      
      async  exportIdn(){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    idateFrom:this.idateFrom,
                    idateTo:this.idateTo
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/incompletenotes/report', requestOptions)
            const data = await res.json()
            if(data.status == true){
                const rawPickups = data.processedPickups;
                const rawDispatches = data.processedDispatches;
                const pickupDatas = rawPickups.map((pickup, index) => ({
                    'No.': index + 1,
                    'Date of request': this.formatDate(pickup.createdAt, true),
                    'Pickup/Dispatch No': pickup.pickupNo != null ? pickup.pickupNo : pickup.dispatchNo,
                    'Generated By': pickup?.generatedBy,
                    'Project': pickup?.project,
                    'Assignee': pickup?.assigneeName,
                    'Approver': pickup?.Approval?.approvedBy.name,
                    'Processed by': pickup?.processedBy,
                    'Problem / Exception': pickup.exceptionComment,
                    'Problem / Exception Comment': pickup?.exceptionDetails,
                    'Resolution': pickup.resolution ? pickup.resolution: "Not yet resolved",
                    'Date of resolution': this.formatDate(pickup.resolvedAt, "true") ? this.formatDate(pickup.resolvedAt, "true") : "N/A",
                    'Duration': Math.round(this.getDuration(pickup.processedAt, pickup.exceptedAt)),
                }));

                const dispatchDatas = rawDispatches.map((dispatch, index) => ({
                    'No.': index + pickupDatas?.length + 1,
                    'Date of request': this.formatDate(dispatch.createdAt, true),
                    'Pickup/Dispatch No': dispatch.pickupNo != null ? dispatch.pickupNo : dispatch.dispatchNo,
                    'Generated By': dispatch?.generatedBy,
                    'Project': dispatch?.project,
                    'Assignee': dispatch?.assigneeName,
                    'Approver': dispatch?.Approval?.approvedBy.name,
                    'Processed by': dispatch?.processedBy,
                    'Problem / Exception': dispatch.exceptionComment,
                    'Problem / Exception Comment': dispatch?.exceptionDetails,
                    'Resolution': dispatch.resolution ? dispatch.resolution: "Not yet resolved",
                    'Date of resolution': this.formatDate(dispatch.resolvedAt, "true") ? this.formatDate(dispatch.resolvedAt, "true") : "N/A",
                    'Duration': Math.round(this.getDuration(dispatch.processedAt, dispatch.exceptedAt)),
                }));

                const idnDatas = [...pickupDatas, ...dispatchDatas];

                const fileName = new Date().getTime()+'_incomplete_note_report';
                const exportType = 'csv';
                const fields = ['No.', 'Date', 'Order No', 'Project','Assignee', 'Approver', 'Processed by', 'Product Description', 
                'Problem / Exception', 'Problem / Exception Comment', 'Resolution', 'Date of resolution', 'Duration'];

                console.log(fields)
                exportFromJSON({ data: idnDatas, fileName, exportType });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'No report found',
                    text: data.message
                })
          }
      },  

       async  exportBatch(){
            alert("Receiving Materials")
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    dateFrom:this.bdateFrom,
                    dateTo:this.bdateTo
                })
            };
            const res = await fetch(process.env.VUE_APP_URL+'/batches/report', requestOptions)
            const data = await res.json()
            if(data.status == true){
                //console.log(data.batches)
                const rawDispatches = data.batches;
                //console.log({rawDispatches})
                const dispatchDatas = rawDispatches.map((dispatch, index) => ({
                    'No.': index + 1,
                    'Date': this.formatDate(dispatch?.createdAt, true),
                    'Product Category': dispatch?.category,
                    'PO No.': dispatch?.purchaseNo,
                    'Ref Doc.': dispatch?.documentType,
                    'Ref 1': dispatch?.reference1,
                    'Ref 2': dispatch?.reference2,
                    'Product Description': dispatch?.ProductName,
                    'Unit': dispatch?.unit,
                    'Quantity': dispatch?.quantity,
                    'Amount': dispatch?.price,
                    'W/House': dispatch?.name,
                    'Stock Category': dispatch?.stockCategory,
                    'Added by': dispatch?.RequestedBy,
                    'Guarded by': dispatch?.GuardedBy,
                    'Verified by': dispatch?.VerifiedBy,
                    'Supplier/Driver': dispatch?.isInternal == true ? dispatch?.DriverBy : dispatch?.SupplierName,
                    'Vehicle': dispatch?.vehicle,
                    'Project/Site Name': dispatch?.project,
                    'Status': dispatch?.status,
                }));
                const fileName = new Date().getTime()+'_receiving_report';
                const exportType = 'csv';                
                const fields = ['No.', 'Date', 'Product Category', 'DN No.','Ref No.', 'Product Description', 'Unit', 'Quantity Requested', 
                'Quantity Dispatched', 'Balance', 'W/House', 'Dispatched by', 'Dispatched to','Assigned to', 'Project/Site Name', 'Vehicle', 'Status'];
                //console.log({dispatchDatas})
                console.log({fields})
                //exportFromJSON({ dispatchDatas, fileName, exportType, fields });
                exportFromJSON({ data:dispatchDatas, fileName, exportType });

            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'No report found',
                    text: data.message
                })
            }
        },                
    }
  }
</script>

<style>
.lds-roller {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002060;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>